<template>
    <div class="content">
        <Header :customstyle="{ background: '#202823' }">
            <template slot="title">online service 在线客服</template>
        </Header>
        <div class="chat-content">
            <div class="word">
                <van-image class="imghead" :src="require('@/assets/images/mine/live.png')">
                    <template v-slot:error>加载失败</template>
                </van-image>
                <div class="info">
                    <p class="time">Chinese and English customer service中英文客服</p>
                    <div class="info-content">Reminder: The submitted feedback and pictures are valid after being reviewed and will be processed directly in a timely manner. Please wait patiently.
                        <br/>
                        温馨提示：提交的反馈与图片收到经审核有效，会及时直接处理，请耐心等待。</div>
                </div>
            </div>
            <div v-for="(itemc,indexc) in chatList" :key="indexc">
                <div class="word" v-if="itemc.reply_id ==1">
                    <van-image class="imghead" :src="require('@/assets/images/mine/live.png')">
                        <template v-slot:error>加载失败</template>
                    </van-image>
                    <div class="info">
                        <p class="time">Chinese and English customer service中英文客服</p>
                        <div class="info-content">{{itemc.reply_content }}</div>
                    </div>
                </div>
                <div class="word-my" v-else>
                    <div class="info">
                        <p class="time">我</p>
                        <div class="info-content" v-if="itemc.images_url ===''">
                            {{itemc.content}}
                        </div>
                        <div class="info-content" v-else>
                            <van-image :src="itemc.images_url">
                                <template v-slot:error>加载失败</template>
                            </van-image>
                        </div>
                    </div>
                     <van-image class="imghead" :src="require('@/assets/images/logo.png')" >
                        <template v-slot:error>加载失败</template>
                    </van-image>
                </div>
            </div>
        </div>
        <div class="send-content">
            <div class="sendbox">
                <van-field class="inputClass" v-model="chatcontent" placeholder="请输入聊天内容"
            ></van-field>
                <van-uploader :max-size="5000 * 1024" :after-read="clickUpload">
                    <img  class="uploadicon" :src="require('@/assets/images/mine/uploadimg.png')"/>
                </van-uploader>
               
                <div class="submitbtn" @click="sendChat">
                    提交
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "../../components/header.vue";
import {problemList, addProblem, uploadImg} from '@/request/api'
import { Toast } from 'vant';
export default {
    components: {
        Header
    },
    data () {
        return{
            chatList: [],
            chatcontent: '',
            address:sessionStorage.getItem("address"),
        }
    },
    activated () {
        if(this.address&& sessionStorage.getItem(this.address)){
            this.getProblemList()
        }
    },
    methods : {
        clickUpload(file) {
            this.uploadImg(file)
        },
        getProblemList () {
            const params = {
                address: this.address
            }
            problemList(params).then(res=> {
                this.chatList = res.data
                // this.chatList =  this.chatList.reverse()
            })
        },
        uploadImg(file) {
            if(!this.address||!sessionStorage.getItem(this.address)){
                Toast('请使用币安智能链链接')
                return false;
            }
            const fd = new FormData()
            fd.append('file', file.file)
            uploadImg(fd).then(res=>{
                console.log(res);
                const params = {
                    address: sessionStorage.getItem("address"),
                    content: this.chatcontent,
                    images_url: res.data.http_url
                }
                addProblem(params).then((res)=> {
                    if (res.code == 200){
                        this.getProblemList()
                    }else if(res.code==10002){
                        Toast('请联系团长发送');
                    }else if(res.code==10001){
                        Toast('今日问题已达上限');
                    }else{
                        Toast('发送失败');
                    }
                })
            })
        },
        sendChat () {
            if(this.chatcontent.length === 0){
                return;
            }
            if(!this.address||!sessionStorage.getItem(this.address)){
                Toast('请使用币安智能链链接')
                return false;
            }
            const params = {
                address: this.address,
                content: this.chatcontent,
                images_url: ''
            }
            addProblem(params).then((res)=> {
                if (res.code==200){
                    this.chatcontent = ''
                    this.getProblemList()
                }else if(res.code==10002){
                    Toast('请联系团长发送');
                }else if(res.code==10001){
                    Toast('今日问题已达上限');
                }else{
                    Toast('发送失败');
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.content {
    min-height: 100vh;
    background: #0D1912;
    .chat-content{
        width: 100%;
        padding: 20px;
        height: calc(100vh - 200px);
        overflow: scroll;
        .word{
            display: flex;
            margin-bottom: 20px;
            .imghead{
                width: 80px;
                height: 80px;
                border-radius: 16px;
            }
            .info{
                margin-left: 20px;
                width: 90%;
                .time{
                    color: rgba(182,217,195,1);
                    font-size: 24px;
                    font-weight: 400;
                    font-family: "PingFang SC";
                    margin: 0;
                    margin-top: -5px;
                 }
                .info-content{
                    position: relative;
                    margin-top: 8px;
                    background: rgba(32,40,35,1);
                    padding: 24px;
                    color: rgba(182,217,195,1);
                    font-size: 28px;
                    font-weight: 400;
                    font-family: "PingFang SC";
                }
                //小三角形
                .info-content::before{
                    position: absolute;
                    left: -8px;
                    top: 8px;

                    border-right: 10px solid rgba(32,40,35,1);
                    border-top: 8px solid transparent;
                    border-bottom: 8px solid transparent;
                }
            }
        }
        
        .word-my{
            display: flex;
            justify-content:flex-end;
            margin-bottom: 20px;
            .imghead{
                width: 80px;
                height: 80px;
                border-radius: 16px;
            }
            .info{
                width: 90%;
                margin-left: 10px;
                text-align: right;
                .time{
                    color: rgba(182,217,195,1);
                    font-size: 24px;
                    margin: 0;
                    margin-top: -5px;
                    margin-right: 10px;
                 }
                .info-content{
                    font-size: 14px;
                    float: right;
                    margin-right: 10px;
                    position: relative;
                    border-radius: 12px;
                    opacity: 1;
                    text-align: left;
                    background: rgba(32,40,35,1);
                    padding: 24px;
                    color: rgba(182,217,195,1);
                    font-size: 28px;
                    font-weight: 400;
                    font-family: "PingFang SC";
                }
                //小三角形
                .info-content::after{
                    position: absolute;
                    right: -8px;
                    top: 8px;
                    content: '';
                    border-left: 10px solid rgba(32,40,35,1);
                    border-top: 8px solid transparent;
                    border-bottom: 8px solid transparent;
                }
            }
        }
    }
    .send-content {
        width: 100%;
        height: 98px;
        opacity: 1;
        background: rgba(32,40,35,1);
        position: fixed;
        bottom: 0px;
        .sendbox {
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: 98px;
            .inputClass{
                width: 430px;
                border-radius: 16px;
                background: #29332D;
                ::v-deep .van-field__control{
                    color: white;
                } 
            }
            .uploadicon {
                width: 60px;
                height: 60px;
            }
            .submitbtn {
                width: 136px;
                height: 72px;
                border-radius: 16px;
                opacity: 1;
                background: rgba(39,204,127,1);
                color: rgba(32,40,35,1);
                font-size: 36px;
                font-weight: 700;
                font-family: "PingFang SC";
                text-align: center;
                line-height: 72px;
            }
        }
    }
}
</style>